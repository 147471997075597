.today--section {
    margin-bottom: 40px;
    padding: 0 50px;
}
.today--section h2 {
    font-size: 20px;
}
.today--section__box {
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.btc--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.btc--section h5 {
    font-size: 30px;
}
.eth--section {
    text-align: center;
    border-right: 1px solid #DAE1E9;
}
.eth--section h5 {
    font-size: 30px;
}
.ltc--section {
    text-align: center;
}
.ltc--section h5 {
    font-size: 30px;
}
@media (max-width: 480px) {
    .eth--section {
        border-right: none;
    }
    .btc--section {
        border-right: none;
    }
    .today--section {
        margin-top:  50px;
    }
}

.category-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    max-height: 400px;
    overflow-y: auto;
    transform: translateY(-100%);
    transition: transform 0.4s ease-in-out;
  }
  
  .modal-content.show {
    transform: translateY(0);
  }

  .button-filter {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: white; /* Warna latar belakang default */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    outline: none;
    transition: none; /* Menghilangkan efek transisi */
  }
  
  .button-filter:hover {
    background-color: transparent; /* Menghilangkan perubahan background saat hover */
    color: inherit; /* Menjaga warna teks tetap sama */
    box-shadow: none; /* Menghilangkan bayangan atau efek lain saat hover */
  }

  .button-filter:active {
    background-color: transparent; /* Menghilangkan background saat tombol ditekan */
    color: inherit; /* Menjaga warna teks tetap sama */
    border: none; /* Menghilangkan border saat aktif */
    box-shadow: none; /* Menghilangkan bayangan atau efek lain saat aktif */
  }
  
  