/* Profile.css */
html, body{
    height: 100%;
    margin: 0;
}

.profile-container-akun {
    max-width: 480px;
    min-height:90vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #ffffff;
}

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-container-akun .profile-header .profile-picture {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 120px;
    height: 120px;
}


.profile-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.additional-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    
}

.profile-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    padding-bottom: 15px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #fff;
    color: #333;
    transition: all 0.3s ease;
    border-bottom: 1px solid #ddd;
}

.profile-button:hover {
    background-color: #f0f0f0;
    border-color: #EA6022;
}

.profile-button .button-icon {
    font-size: 1.2rem;
    color: #EA6022;
}

.profile-button .button-text {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
}

.profile-button .button-arrow {
    font-size: 1rem;
    color: #999;
}

.container-bottom {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.logout {
    background-color: #f44336;
    color: white;
    border: none;
    max-width: 120px;
    text-align: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 30px;
}

.logout:hover {
    background-color: #e53935;
}

.break{
    width: 100%;
}
.break-line{
    border: #888888 solid 1px;
    display: block;
    width: 100%;
}

.login-button {
    display: inline-block;
    background-color: #EA6022;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none; /* Menghilangkan underline */
    text-align: center;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #d4551d;
}

.profile-picture{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
