.rekap-container {
    background-color: #F5F5F5;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100vh;
}

.rekap-card {
    background-color: #ffffff;
    width: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row;  /* Horizontal layout for left and right columns */
    text-align: left;
    margin-bottom: 20px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 10px;
}

.rekap-card-detail {
    background-color: #ffffff;
    width: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row;  /* Horizontal layout for left and right columns */
    text-align: left;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
}


.foto-container-rekap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;  /* Add space between image and text */
    margin-left: 10px;
    width: 10%;
    height: 10%;
}

.foto-rekap {
    display: flex;
    width: 200%;
    height: 200%;
    object-fit: cover;
    border-radius: 50%;
}


.santri-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.santri-info h2 {
    font-size: 18px;
    margin: 10px 0;
    color: #333;
}

.progress-bar-container {
    margin: 10px 0;
}

.progress-bar-background {
    width: 100%;
    height: 12px;
    background-color: #e0e0e0;
    border-radius: 10px;
    margin: 5px 0;
}

.progress-bar {
    height: 100%;
    background-color: #EA6022;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    margin-top: 5px;
    color: #555;
    font-size: 14px;
}

.rekap-footer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
}

.rekap-footer p {
    margin: 5px 0;
}

.filter-container {
    display: flex;
    margin: 20px;
    gap: 1rem; /* Jarak antar filter */
    align-items: center; /* Pusatkan secara vertikal */
}

.filter-item {
    display: flex;
    flex-direction: column;
}

.label-filter {
    text-align: center;
}

.filter-dropdown {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
}


.total-container {
    margin: 20px 20px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.total-tagihan,
.total-terbayar {
    font-size: 18px;
}

.rekap-table {
    width: 100%;
    border-collapse: collapse;
}

.rekap-table th,
.rekap-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.rekap-table th {
    background-color: #f4f4f4;
}

.rekap-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.rekap-cards-container{
    margin-left: 20px;
    margin-right: 20px;
}

.no-data {
    text-align: center;
    margin-top: 50px;
}

.no-data-image {
    max-width: 150px;
    margin-bottom: 20px;
}

.no-data-text {
    font-size: 18px;
    color: #666;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    margin-top: 5px;
}

.button-container label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.button-pembayaran {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 90px;
    text-align: center;
}

.button-pembayaran:hover {
    background-color: #45a049;
    opacity: 0.9;
}

.button-pembayaran:nth-child(2) {
    background-color: #2196F3;
}

.button-pembayaran:nth-child(2):hover {
    background-color: #1e88e5;
}

.button-pembayaran:nth-child(3) {
    background-color: #FF9800;
}

.button-pembayaran:nth-child(3):hover {
    background-color: #fb8c00;
}
