.profile-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-profile {
    text-align: center;
    margin-bottom: 20px;
}

.profile-picture {
    width: 125px;
    height: 125px;
    border-radius: 50%;
}

.profile-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.profile-info {
    width: 400px;
    margin-left: 20px;
    margin-right: 20px;
}

.info-item-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin-left: 20px;
    margin-right: 20px;
}

.info-content {
    display: flex;
    align-items: center;
    flex: 1;
}

.info-label {
    font-weight: bold;
    color: #333;
}

.info-icon {
    color: #555;
    margin-right: 10px;
    font-size: 20px; /* Sesuaikan ukuran */
}

.info-text {
    font-size: 1rem;
    color: #555;
}

.info-column {
    display: flex;
    flex-direction: column;
    flex: 1; /* Memastikan kolom menyesuaikan ruang */
    margin-left: 10px;
    align-items: start;
}

.chevron-icon {
    color: #EA6022;
    font-size: 16px;
    margin-right: 20px;
}

.edit-profile {
    text-align: center;
    margin-bottom: 20px;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9998; /* Overlay di bawah modal */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: fixed;
    bottom: -150px;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 9999; /* Modal di atas overlay */
}

.modal-content-password {
    position: fixed;
    bottom: 10px;
    border-radius: 20px;
    background-color: #fff;
    width: 100%;
    max-width: 480px;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 9999; /* Modal di atas overlay */
}

.modal-actions {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 15px;
    gap: 10px;
}

.modal-actions button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-actions button:first-child {
    color: #EA6022;
    background-color: #fff;
}

.modal-actions button:last-child {
    color: #EA6022;
    background-color: #fff;
}

.modal-actions button:hover {
    opacity: 0.8;
}

.modal-title {
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.modal-title-password {
    font-size: 16px;
    font-weight: 700;
    color: #333;
}

.modal-value{
    font-size: 14px;
    border-bottom: #EA6022  solid 2px;
    width: 100%;
}

.modal-value-password {
    font-size: 14px;
    border-bottom: #EA6022  solid 2px;
    width: 100%;
    margin-top: 20px;
}

.password-button {
    background-color: #EA6022; /* Warna tombol */
    color: #fff; /* Warna teks */
    padding: 8px 15px; /* Padding */
    margin-top: 20px;
    border: none; /* Hilangkan border */
    border-radius: 15px; /* Membuat sudut melengkung */
    font-weight: 500;
    cursor: pointer; /* Ubah kursor menjadi pointer */
    font-size: 16px; /* Ukuran font */
    justify-content: center;
    transition: background-color 0.3s ease; /* Animasi hover */
}

.password-button-container{
    justify-content: center;
    display: flex;
}

.password-button:hover {
    background-color: #0056b3; /* Warna saat hover */
}





    