.content-boxed {
    margin-top: 20px;
    padding: 20px;
  }
  
  #student-info-content {
    display: flex;
    justify-content: center; /* Memusatkan konten */
  }
  
  .student-container {
    display: flex;
    align-items: center; /* Menyelaraskan item secara vertikal */
    gap: 20px; /* Memberi jarak antara foto dan data */
    max-width: 800px;
    width: 100%;
  }
  
  .student-photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    justify-content: left;
}
  
  .student-desc {
    text-align: left;
    margin-left: 30px; /* Rata kiri untuk deskripsi */
  }
  
  .student-desc p {
    margin-bottom: 5px;
    font-size: 16px;
  }
  
  .student-name,
  .academic-year,
  .class-name {
    font-weight: bold;
    font-size: 10px;
  }
  
  .payment-card {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .label-input {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .input-nominal {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  /* Menghilangkan spinner pada input type number di semua browser */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
  .btn-pay {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-weight: 400px;
    color: #fff;
    background-color: #D65920;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .btn-pay:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .btn-payment-method {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 400px;
    color: #fff;
    background-color: #F74555;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .rekap-card-detail {
    background-color: #ffffff;
    width: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: row; /* Horizontal layout */
    text-align: left;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  .foto-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 100px;
    height: 100px;
  }
  
  .foto {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .santri-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
  }
  
  
  .info-detail {
    display: flex;
    justify-content: space-between; /* Menyusun item secara horizontal */
    width: 100%;
  }
  
  .info-item {
    flex: 1; /* Menyebar secara merata */
    margin-right: 10px; /* Memberi jarak antar elemen */
  }
  
  .info-item p {
    font-size: 14px;
    margin: 5px 0;
    color: #333;
  }
  
  .tagihan-info {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
  }

  .rekap-card-detail {
    margin: 20px 0;
}

.santri-info {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.info-row-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
  margin-top: 5px;
}

.info-item {
  display: flex ;
  flex-direction: column ;
}

.info-left {
  justify-content: flex-start;
  text-align: left;
  flex: 1; /* Agar kolom kiri menggunakan ruang tersedia */
}

.info-right {
  justify-content: flex-end;
  text-align: right;
  flex: 1; /* Agar kolom kanan menggunakan ruang tersedia */
}


.info-tagihan {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.info-label {
    font-size: 16px;
    font-weight: bold;
    color: #444;
}


.info-data {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.info-value {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-align: right;
}

.label-input {
    margin-bottom: 10px;
    display: block;
    font-size: 14px;
    font-weight: bold;
}

.input-nominal {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.payment-card {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

.btn-pay {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.btn-pay:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

  